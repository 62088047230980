<template>



  <div v-if="carregandoPagina" class="col-md-12" style="text-align: center;padding-top: 255px;">
    <pm-ProgressSpinner />
  </div>
  <section v-if="!carregandoPagina">
    <pm-Breadcrumb :home="home" :model="items_bread" />
    <div class="row">
      <div class="col-md-7">
        <h2 class="titulo-links-escolha" style="vertical-align:middle !important">
          Registrar Alunos <span class="cabecario-turma2" v-if="this.ativa == 1"> Turma Habilitada </span>
        </h2>
      </div>
      <div class="col-md-5" >
        <pm-Button  type="button" @click="this.$router.push({name: 'coordenacao-turma-hab', params: {pg: this.pg} });"
          icon="pi pi-angle-left"
          label="VOLTAR" title="VOLTAR"  class="p-button-sm p-button-danger btn-color" style="margin-left:5px;margin-top: 35px;float: right;">
        </pm-Button>

        <pm-Button  type="button" @click="irOutraPg(0)"
          icon="pi pi-id-card"
          label="DISCIPLINAS" title="DISCIPLINAS"  class="p-button-sm p-button-info btn-color" style="margin-left:5px;margin-top: 35px;float: right;">
        </pm-Button>

        <pm-Button  type="button" @click="irOutraPg(1)"
          icon="pi pi-id-card"
          label="PROFESSOR" title="PROFESSOR"  class="p-button-sm p-button-info btn-color" style="margin-top: 35px;float: right;">
        </pm-Button>
      </div>
    </div>
    <div class="pm-card">
      <!-- <pm-PickList v-model="alunos_item"
             @move-to-target="moveToTarget"  @move-to-source="moveToSource"
             listStyle="height:342px" dataKey="id" id="alunturma">
              <template #sourceheader>
                  <div class="p-input-filled" style="margin-top:10px">
                    <pm-InputText placeholder="Buscar Alunos " type="text" style="width:350px;" v-model="aluno" @keyup.enter="buscarAlunos"/>
                  </div>
              </template>
              <template #targetheader>
                  Alunos da Turma
              </template>

              <template #movecontrolsstart>
              </template>

              <template #item="slotProps">
                  <div >
                    <div >
                        <div>
                            <span class="">
                              <span class="badge badge-secondary2" style="margin-right:15px;">{{slotProps.item.matricula}}</span>
                               {{slotProps.item.nome_completo}}
                              <span style="margin-left:10px;" class="badge bg-danger table_style" :title="slotProps.item.qual_deficiencia" v-if="slotProps.item.deficiencia == 1">NEE</span>
                            </span>
                        </div>
                    </div>
                  </div>
              </template>
        </pm-PickList> -->
      <div class="row">
        <div class="col-md-6">
          <pm-DataTable :value="alunos" v-model:selection="alunoSelecionado" dataKey="id" class="p-datatable-customers"
            stripedRows scrollable :rowHover="true" :selectionMode="selectType"  :metaKeySelection="false" :resizableColumns="true" scrollHeight="400px" :virtualScrollerOptions="{ itemSize: 46 }">
            <template #header>
              <div class="flex justify-content-center align-items-center" style="padding: 0px">
                <h5 class="m-0">Buscar Alunos</h5>
                <span class="p-input-icon-left col-3" style="padding: 0px">
                  <pm-InputText placeholder="Nome " type="text" style="width:170px;" v-model="aluno" @keyup.enter="buscarAlunos"/>
                </span>
                <span class="p-input-icon-left col-6" style="padding: 0px">
                  <pm-InputText placeholder="Sobrenome" type="text" style="width:200px;" v-model="alunosobrenome" @keyup.enter="buscarAlunosSobrenome"/>
                </span>
              </div>
            </template>

            <!-- <pm-Column selectionMode="multiple" style="flex: 0 0 3.5rem; "></pm-Column> -->

            <pm-Column field="matricula" header="Matrícula" style="flex: 0 0 7.5rem">
              <template #body="alunos">
                <span class="badge bg-secondary">{{alunos.data.matricula}}</span>
              </template>
            </pm-Column>

            <pm-Column field="nome_completo"  header="Nome" style="min-width: 200px"></pm-Column>

          </pm-DataTable>
          <pm-Button v-if="alunoSelecionado" :disabled="matriculaEncerrada == true && ativa == 1" style="float:right;" icon="pi pi-plus" class="mt-2 p-button-sm" label="Adicionar à Turma" @click="addAluno"></pm-Button>
          <pm-Button v-if="alunoSelecionado" :disabled="matriculaEncerrada == false || ativa == 0" style="float:right;" icon="pi pi-clock" class="mt-2 mr-3 p-button-sm" label="Matrícula Tardia" @click="matriculaTardia"></pm-Button>
        </div>

        <div class="col-md-6">
          <pm-DataTable :value="alunosTurma" @change=verificarSeOAlunoSaiuDaTurma() v-model:selection="alunoSelecionado" dataKey="id" class="p-datatable-customers"
            scrollable scrollHeight="400px" selectionMode="single" @dblclick="deletarAluno" :metaKeySelection="false" :virtualScrollerOptions="{ itemSize: 46 }">
            <template #header>
              <div class="flex justify-content-center align-items-center" style="padding: 7px">
                <h5 class="m-0">Alunos na Turma</h5>
                <!-- <span class="p-input-icon-left col-6" hidden style="padding: 0px">
                    <i class="pi pi-search" />
                    <pm-InputText placeholder="Buscar Alunos " type="text" style="width:320px;" v-model="aluno2" @keyup.enter="buscarAlunos"/>
                  </span> -->
              </div>
            </template>

            <!-- <pm-Column selectionMode="single" style="flex: 0 0 3.5rem"></pm-Column> -->

            <pm-Column field="matricula" header="Matricula" style="flex: 0 0 7.5rem">
              <template #body="alunosTurma">
                <span class="badge bg-secondary">{{alunosTurma.data.matricula}}</span>
              </template>
            </pm-Column>

            <pm-Column field="nome_completo" header="Nome" style="min-width: '200px'"></pm-Column>


            <!-- <pm-Column field="nome_completo" header="Nome" style="min-width: '200px'"></pm-Column> -->


          </pm-DataTable>
          <pm-Button v-if="alunoSelecionado && alunoSelecionado.status == 'Normal'"  style="float:right;" icon="pi pi-trash" label="Tirar da Turma" class="p-button-danger mt-2" @click="deletarAluno"></pm-Button>
        </div>

      </div>
    </div>

    <va-modal v-model="abrirModalRemover"  hide-default-actions >
      <template #header>
        <h2 style="color: #2196f3;">Remover Aluno na Turma</h2>
      </template>
      <slot>
        <p><b>Deseja remover o aluno(a) <span class="badge badge-secondary2" style="font-size: 15px!important;">{{nome_aluno}}</span> ? Selecione o motivo!</b></p>
        <div class="row" style="margin-bottom:20px;">
          <div class="col-md-12">
            <fieldset>
              <va-radio
                option="option0"
                v-model="radioSelectedOption"
                label="Transferência interna do aluno"
              />
              <br>
              <va-radio
                option="option1"
                v-model="radioSelectedOption"
                label="Saiu da escola (Transferido)"
              />
              <br>
              <va-radio
                option="option2"
                v-model="radioSelectedOption"
                label="Saiu da escola (Evadido)"
              />
              <br>
              <va-radio style="margin-top:10px;"
                option="option3"
                v-model="radioSelectedOption"
                label="Faleceu"
              />
              <br>
              <va-radio style="margin-top:10px;"
                option="option4"
                v-model="radioSelectedOption"
                label="Reclassificado"
              />
              <br>
              <!-- <va-radio style="margin-top:10px;"
                disabled
                option="option5"
                v-model="radioSelectedOption"
              > Remover(Apagará todos os dados de presença e notas do aluno nesta turma) <b class="ml-2 badge bg-secondary">Desabilitado</b>

              </va-radio> -->
            </fieldset>
          </div>
        </div>
        <pm-Button v-if="radioSelectedOption != 'option5'"  type="button"  @click="transferenciaPrimeiraEtapa"
          label="Realizar envio de dados para transferência" title="Realizar ação"  class=" p-button btn-color">
        </pm-Button>
        <pm-Button v-if="radioSelectedOption == 'option5'"  type="button"  @click="removerAlunoMotivo"
          label="Realizar Remoção" title="Realizar ação"  class=" p-button btn-color">
        </pm-Button>
      </slot>
    </va-modal>

    <pm-Dialog header="Matrícula Tardia" v-model:visible="dialogMatriculaTardia" :closable="false" :style="{width: '50vw'}" :modal="true">

      <p class="mb-1 p-0"><b>Preencha as informações necessárias do aluno:</b></p>

      <p class="m-0 p-0">Selecione o período que deseja inserir os dados do aluno.</p>

      <div class="grid mt-2">
        <div
          v-for="(periodo, index) of periodos"
          :key="periodo.id"
          class="field-checkbox md:col-3"
        >
          <pm-Checkbox
            @change="okToSend = false;"
            :inputId="periodo.id"
            name="periodo"
            :value="periodo"
            v-model="listaPeriodos"
            :disabled="index > 0 && !listaPeriodos.includes(periodos[index - 1]) || (index + 1) < listaPeriodos.length"   
            v-tooltip="index > 0 ? 'Para selecionar este período, é necessário que o(s) período(s) anterior(es) esteja(m) selecionado(s). Para desmarcar este período, é necessário que o(s) período(s) posterior(es) esteja(m) desmarcado(s).' : ''"/>
          <label :for="periodo.id">{{ periodo.nome }}</label>
        </div>
      </div>

      <div>
        <p class="m-0" v-if="listaPeriodos.length">Insira os dados abaixo:</p>

        <div class="mb-4" v-for="(periodo, periodoIndex) in listarPeriodosOrdenada" :key="periodo.id">
          <pm-DataTable :value="periodo.disciplinas" editMode="cell" dataKey="id" class="p-datatable-customers"
            stripedRows scrollable :rowHover="true" selectionMode="multiple" :resizableColumns="true">
            <pm-Column field="" :header="periodo.nome">
              <template #body="body">
                <div v-for="(disciplina, index) in body" :key="disciplina" class="">
                  <span>{{ disciplina.nome }}</span>
                </div>
              </template>
            </pm-Column>
            <pm-Column field="presenca" :header="'Frequência % (Entre 0 e 100)'" bodyStyle="text-align: right; !important" style="text-align: center !important; ">
              <template #body="body">
                <span style="text-align: center !important;">{{ body.data.presenca }}</span>
              </template>
              <template #editor="{data, index}">
                <pm-InputText style="text-align: center !important;"
                  v-model="data.presenca"
                  autofocus
                  v-on:keypress="isNumber($event)"
                  @change="onRowEditSave(data, periodoIndex, index); buttonIsActive();" />
              </template>
            </pm-Column>
          </pm-DataTable>
        </div>

        <div class="col-12 m-0 p-0 flex justify-content-between">
          <div class="col-11 m-0 p-0">
            <pm-Button type="button"  @click="matriculaTardia"
              label="Voltar" title="Voltar"  class="p-button-danger p-button-sm">
            </pm-Button>
          </div>
          <div class="col-1 m-0 p-0" >
            <pm-Button v-if="okToSend" type="button"  @click="addMatriculaTardia"
              label="Adicionar" title="Adicionar"  class="p-button p-button-sm">
            </pm-Button>
          </div>
        </div>
      </div>
    </pm-Dialog>
  </section>
</template>

<script>

import { Aluno } from "@/class/alunos";
import axios from 'axios'
import { defineComponent } from "vue";
import { Turma } from "@/class/turma.js";
import { Transferecia } from "@/class/transferencia.js";
import { SomaCumulativa } from "@/class/somaCumulativa";
import { SegmentoEscolar } from "@/class/segmentoEscolar.js";
import { SeriesEscolar } from "@/class/serie.js";

import { MatriculaTardia } from "@/class/matriculaTardia.js";

export default defineComponent({
  props: {
    etapa_id:{},
    periodo_id:{},
    turma_id:{},
    escola_id:{},
    turno:{},
    ativa:{},
    //Se for 0, nao faz nada. Se for 1 adicionar apenas disciplinas e se for 2 adicionar apenas alunos
    tipoDeAcao: {
      default: 0,
    },
    erroturmaeditar:{},
    pg:{}
  },
  data() {
    return {
      home: {icon: 'pi pi-home', to: '/admin/painel-adm'},
      items_bread: [
        {label: 'Turmas'},
      ],
      radioSelectedOption: "option0",
      abrirModalRemover:false,
      tipo_segmento: null,
      tipo_serie: null,

      ano:null,
      aluno:null,
      alunosobrenome:null,
      aluno2:null,
      alunos:[],
      alunosTurma:[],
      alunos_item: [],

      nome_aluno:null,
      aluno_id:null,
      alunoTurmaTransferida:0,
      turmasRelacionadas:[],
      alunoSelecionado: null,
      carregandoPagina:false,
      situacaoAno:0,
      matriculaEncerrada: false,
      dialogMatriculaTardia: false,
      periodos: [
        {id: 1, nome: '1° período'},
        {id: 2, nome: '2° período'},
        {id: 3, nome: '3° período'},
        {id: 4, nome: '4° período'},
      ],
      disciplinas: [],
      listaPeriodos: [],
      periodosDisciplinas: [],
      selectType: "",
      okToSend: false
    };
  },

  computed: {
    /**
     * Ordena os periodos de acordo com o nome
     * e adiciona as disciplinas na ordem correta
     *
     * @return Array
     */
    listarPeriodosOrdenada() {

      const periodosOrdenados = [...this.listaPeriodos].sort((a, b) => a.nome.localeCompare(b.nome));

      const periodosDisciplinasAtualizados = periodosOrdenados.map(periodo => {
        const disciplinasDoPeriodo = this.periodosDisciplinas.find(p => p.id === periodo.id)?.disciplinas?.concat() || [];
        
        return {
          ...periodo,
          /**
           * Disciplinas do periodo em questão
           */
          disciplinas: [
            /**
             * Disciplinas ja existentes no periodo
             */
            ...disciplinasDoPeriodo, 
            /**
             * Disciplinas que nao existem no periodo
             */
            ...this.disciplinas.filter(d => !disciplinasDoPeriodo.some(dd => dd.id === d.id)).map(disciplina => ({ ...disciplina }))
          ]
        };
      });
      /**
       * Periodos atualizados
       */
      this.periodosDisciplinas = periodosDisciplinasAtualizados;
      this.buttonIsActive();
      return this.periodosDisciplinas;
    }

  },
  methods: {
    isNumber(e) {
      const char = String.fromCharCode(e.keyCode); 
      const currentValue = e.target.value + char; 
      if (/^(100|[1-9]?[0-9]?)$/.test(currentValue)) {return true;} 
      else {e.preventDefault();}
    },
    fundamentalUmOk(){
      const isFundamentalOneOrEja = this.tipo_segmento === 1 || (this.tipo_segmento === 3 && this.tipo_serie === 1);
      const percentage = this.periodosDisciplinas[0].disciplinas[0].presenca;

      return isFundamentalOneOrEja ?
        this.periodosDisciplinas.every(period => period.disciplinas.every(disc => disc.presenca === percentage)) :
        true;
    },
    cleanUpFundamentalUmDisciplinas(){
      const isFundamentalOneOrEja = this.tipo_segmento === 1 || (this.tipo_segmento === 3 && this.tipo_serie === 1);
      if (isFundamentalOneOrEja) {
        const percentual =  this.periodosDisciplinas[0].disciplinas[0].presenca;
        for (let i = 0; i < this.periodosDisciplinas.length; i++) {
          this.periodosDisciplinas[i].disciplinas = [{id: 1, nome: 'TODAS DISCIPLINAS', sigla: 'TD', presenca: percentual, carga_horaria: 20, status: 1}];
        }
      }
    },
    async addMatriculaTardia(){
      if (!this.fundamentalUmOk()) {
        this.$vaToast.init({
            message: "Para turmas do Ensino Fundamental 1 e EJA anos iniciais, o percentual de presença deve ser igual para todas as disciplinas!",
            iconClass: 'fa-star-o',
            position: 'top-right',
            duration: 6000,
            color: 'warning',
            fullWidth: false,
        });
        return false;
      }
      this.cleanUpFundamentalUmDisciplinas();
      try {
        for (let i = 0; i < this.periodosDisciplinas.length; i++) {
          const periodo = this.periodosDisciplinas[i];

          for (let j = 0; j < periodo.disciplinas.length; j++) {
            const disciplina = periodo.disciplinas[j];

            const info = {
              ano: this.ano,
              aluno_id: this.alunoSelecionado.id,
              periodo: periodo.id,
              disciplina_id: disciplina.id,
              turma_id: this.turma_id,
              presencaPorcentagem: Number(disciplina.presenca)
            };

            const data = await MatriculaTardia.cadastrar(info);
          }
        }
        //adicionar soma
        SomaCumulativa.addSomaCumulativa(this.ano, this.alunoSelecionado.id, this.turma_id);
        this.matriculaTardia();
        this.addAluno();


        this.$vaToast.init({
            message: "Operação realizada com sucesso!",
            iconClass: 'fa-star-o',
            position: 'top-right',
            duration: 3000,
            color: 'success',
            fullWidth: false,
        });
      } catch (error) {
        console.log(error)
        this.$vaToast.init({
            message: "Algo deu errado, tente novamente!",
            iconClass: 'fa-star-o',
            position: 'top-right',
            duration: 3000,
            color: 'danger',
            fullWidth: false,
        });
      }
    },
    onRowEditSave(disciplina, periodoIndex, index) {
      const newValue = disciplina.presenca;
      this.periodosDisciplinas[periodoIndex].disciplinas[index].presenca = newValue;
    },
    buttonIsActive() {
      let status = true;
      if (!this.periodosDisciplinas.length) {
        return this.okToSend = false;
      }
      status = !this.periodosDisciplinas.some(periodo => 
          periodo.disciplinas.some(disciplina => disciplina.presenca === '')
      );
      this.okToSend = status;
    },
    matriculaTardia(){
      this.periodosDisciplinas = [];
      this.listaPeriodos = [];
      this.dialogMatriculaTardia = !this.dialogMatriculaTardia;
      this.okToSend = false;
    },
    getMotivo() {
        let motivo = "";
        if (this.radioSelectedOption == "option0") {
          motivo = "Transferido Internamente";
        }
        if (this.radioSelectedOption == "option1") {
          motivo = "Transferido Externamente";
        }
        else if (this.radioSelectedOption == "option2") {
          motivo = "Evadido";
        }
        else if (this.radioSelectedOption == "option3") {
          motivo = "Falecido";
        }
        else if (this.radioSelectedOption == "option4") {
          motivo = "Reclassificado";
        }
        else if (this.radioSelectedOption == "option5") {
          motivo = "Entrou na turma errada";
        }
        return motivo;
    },

    async removerAlunoMotivo() {
      try {
        this.carregandoPagina = true;

        const novo = {
          motivo: this.getMotivo(),
          turma_id: this.turma_id,
          aluno_id: this.alunoSelecionado.id,
        }
        const data = await Aluno.RemoverAlunoTurma(novo);
        this.alunoSelecionado = false;
        await this.buscarAlunoNaTurma(0);

        this.abrirModalRemover = !this.abrirModalRemover;
        this.$vaToast.init({
            message: "Operação realizada com sucesso!",
            iconClass: 'fa-star-o',
            position: 'top-right',
            duration: 3000,
            color: 'success',
            fullWidth: false,
        });
        this.carregandoPagina = false;
      }
      catch(e) {
        this.carregandoPagina = true;
        this.$vaToast.init({
            message: "Problema ao transferir aluno!",
            iconClass: 'fa-star-o',
            position: 'top-right',
            duration: 3000,
            color: 'danger',
            fullWidth: false,
        });
      }
    },

    transferenciaPrimeiraEtapa() {
      const dados = {
          aluno_id: this.alunoSelecionado.id,
          turma_inicial: this.turma_id,
          tipo_evento: this.getMotivo()
      }
      this.carregandoPagina = true;
      Transferecia.store(dados).then((resp) => {
      this.$vaToast.init({
            message: resp.data.message,
            iconClass: 'fa-star-o',
            position: 'top-right',
            duration: 3000,
            color: 'success',
            fullWidth: false,
        });

        setTimeout(() => {
          if (window.confirm("Você deseja finalizar a transferência do aluno agora?")) {
            this.$router.push({name: 'coordenador/transferencia'});          
          }
        }, 600);

      }).catch((err) => {
        this.$vaToast.init({
            message: err.response.data.error,
            iconClass: 'fa-star-o',
            position: 'top-right',
            duration: 3000,
            color: 'danger',
            fullWidth: false,
        });
      }).finally(() => {
        this.carregandoPagina = false;
        this.abrirModalRemover = !this.abrirModalRemover;
        this.radioSelectedOption = 'option0';
      });
    },

    irOutraPg(pg){
      if(pg == 0){
       this.$router.push({name: 'turma-add-disciplina-coordenacao', params: {etapa_id: this.etapa_id, periodo_id: this.periodo_id, turma_id: this.turma_id,
       escola_id: this.escola_id, turno: this.turno} });
      }
      if(pg == 1){
       this.$router.push({name: 'turma-add-professor-coordenacao', params: {etapa_id: this.etapa_id, periodo_id: this.periodo_id, turma_id: this.turma_id,
       escola_id: this.escola_id, turno: this.turno} });
      }
      if(pg == 2){
       this.$router.push({name: 'turma-add-aluno-coordenacao', params: {etapa_id: this.etapa_id, periodo_id: this.periodo_id, turma_id: this.turma_id,
       escola_id: this.escola_id, turno: this.turno} });
      }
    },

    async realizarTranferenciaAluno() {
      try {
        this.carregandoPagina = true;
        const novo = {
          turma_id: this.turma_id,
          turma_id_nova: this.alunoTurmaTransferida,
          aluno_id: this.alunoSelecionado.id,
        }
        const data = await Aluno.TransferenciaInternaAluno(novo);
        this.alunoTurmaTransferida = 0;

        this.abrirModalRemover = !this.abrirModalRemover;

        this.alunos_item = [];
        this.alunoSelecionado = false;
        await this.buscarAlunoNaTurma(0);
        this.$vaToast.init({
            message: "Aluno transferido com sucesso!",
            iconClass: 'fa-star-o',
            position: 'top-right',
            duration: 3000,
            color: 'success',
            fullWidth: false,
        });
        this.carregandoPagina = false;
      }
      catch(e) {
        this.carregandoPagina = false;
        this.$vaToast.init({
            message: "Problema ao transferir aluno!",
            iconClass: 'fa-star-o',
            position: 'top-right',
            duration: 3000,
            color: 'danger',
            fullWidth: false,
        });
      }
    },

    async buscarAlunos(event) {
      if(this.aluno == null){
        this.buscarAlunoNaTurma(0);
      }
      const ano = sessionStorage.getItem("anoSelecionado");
      const data =  await axios.get('/alunos/v2/'+this.aluno+'/'+this.ano);
      this.alunos = [];
      this.alunos = data.data;

      this.alunos_item = [];
      this.buscarAlunoNaTurma(1);
    },
    async buscarAlunosSobrenome(event) {
      if(this.aluno == null){
        this.buscarAlunoNaTurma(0);
      }
      const ano = sessionStorage.getItem("anoSelecionado");
      const data =  await axios.get('/alunos/v2/'+this.aluno+'/sobrenome/'+this.alunosobrenome+'/1/'+ano);
      this.alunos = [];
      this.alunos = data.data;

      this.alunos_item = [];
      this.buscarAlunoNaTurma(1);
    },

    async buscarAlunoNaTurma(n){
      try {
        if(n==0){
          await this.alunosQNaoSaoDaTurma();
        }

        const data = await Turma.obtemAlunosTurma(this.turma_id);
        //pegar os alunos apenas que estejam 'Normal'
        this.alunosTurma = data.data.filter(obj => obj.status == "Normal" || obj.status == "Semestre 1 Encerrado");

        this.alunos_item.push(this.alunos);
        this.alunos_item.push(this.alunosTurma);
      } catch (e) {
        this.carregandoPagina = false;
      }
    },

    async alunosQNaoSaoDaTurma(){
      try {
        const data = await Aluno.obtemTodos2();
        // this.alunos = [];
        this.alunos = data.data;

      } catch (e) {
        this.carregandoPagina = false;
      }
    },

    async identificarTipoSegmento(){
      const data = await SegmentoEscolar.obtemUm(this.etapa_id);
      this.tipo_segmento = data.data.tipo;

      const dataFinalMat = new Date(data.data.calendario_config.data_final_matricula);
      const dataHoje = new Date();

      dataHoje > dataFinalMat ? this.matriculaEncerrada = true : this.matriculaEncerrada = false;
    },

    async identificarTipoSerie(){
      const data = await SeriesEscolar.obtemUm(this.periodo_id);
      this.tipo_serie = data.data.tipo;
    },


    async turmaDisciplina(){
      const data = await Turma.obtemTodasAsDisciplinasTurma(this.turma_id);
      data.data.forEach(element => {
        element.disciplina.presenca = '';
        this.disciplinas.push(element.disciplina);
      });
    },

    async turmaConfig(){
       const turma = {
        turma_id: this.turma_id,
        etapa_id: this.etapa_id,
        periodo_id: this.periodo_id,
        escola_id: this.escola_id,
      }

      const resp_turma = await Turma.aturma(turma);
      const  tt = [
        {
          label: resp_turma.data[0].escola
        },
        {
          label: resp_turma.data[0].etapa
        },
        {
          label: resp_turma.data[0].periodo
        },
        {
          label: resp_turma.data[0].turma
        },
        {
          label: resp_turma.data[0].turno
        },
      ];

      this.items_bread = tt;
    },

    async addAluno(/*payload*/){
      if (this.alunoSelecionado == null) {
        this.$vaToast.init({
          message: "Selecione um aluno",
          iconClass: 'fa-star-o',
          position: 'top-right',
          duration: 3000,
          color: 'warning',
          fullWidth: false,
        });
      }else{
        // this.carregandoPagina = true;
        let aluno;
        if (this.alunoSelecionado.length == null || this.alunoSelecionado == 0) {
          const arrayAlunoSelecionado = [this.alunoSelecionado];
          this.alunoSelecionado = arrayAlunoSelecionado;
        }
        for (let index = 0; index < this.alunoSelecionado.length; index++) {
          aluno = {
            ano: this.ano,
            turma_id: this.turma_id,
            aluno_id: this.alunoSelecionado[index].id,
          }
          // aluno.aluno_id.push(this.alunoSelecionado[index].id);
          // aluno.ano = this.ano;
          // aluno.turma_id = this.turma_id;
          try{
            const data = await Aluno.addAlunoTurma(aluno);
          }catch(e){
            this.$vaToast.init({
              message: e.response.data.erro,
              iconClass: 'fa-star-o',
              position: 'top-right',
              duration: 3000,
              color: 'danger',
              fullWidth: false,
            });
          }
        }
        this.alunosQNaoSaoDaTurma()
        this.buscarAlunoNaTurma(1);
        this.alunoSelecionado = false;
        setTimeout(function() {
         this.buscarAlunoNaTurma(0);
        }, 1000);
        this.carregandoPagina = false;
      }
    },

    async deletarAluno(event){
      if(this.alunoSelecionado == null){
        this.$vaToast.init({
          message: "Selecione um aluno",
          iconClass: 'fa-star-o',
          position: 'top-right',
          duration: 3000,
          color: 'warning',
          fullWidth: false,
        });
      }else{
        this.carregandoPagina = true;
        const payload = {
          ano: this.ano,
          aluno_id: this.alunoSelecionado.id,
          turma_id: this.turma_id,
        };
        const dt = await Aluno.deletarAlunoTurmaV2(payload);
        if(dt.data == "Turma Ativa"){
          this.abrirModalRemover = true;
          this.alunos = [];
          await this.buscarAlunoNaTurma(0);
          this.nome_aluno = this.alunoSelecionado.nome_completo;
          await this.buscaTurmasRelacionadas();
        }
        this.carregandoPagina = false;
        await this.buscarAlunoNaTurma(0);
      }
    },

    // moveToTarget(event){
    //   let payload = {
    //     ano: this.ano,
    //     // aluno_id: event.items[0].id,
    //     aluno_id: this.alunos.id,
    //     turma_id: this.turma_id,
    //   };
    //   // for (let index = 0; index < this.alunos.length; index++) {
    //   //   this.alunos[index];
    //   //   payload.aluno_id = this.alunos.id;
    //   // }
    //   // for (const el of this.alunos) {
    //   //   payload.aluno_id = el.id;
    //   // }
    //   this.addAluno(payload);
    // },

    // moveToSource(event){
    //   let payload = {
    //     ano: this.ano,
    //     aluno_id: event.items[0].id,
    //     turma_id: this.turma_id,
    //   };
    //   this.aluno_id = event.items[0].id;
    //   this.deletarAluno(payload,event);
    // },



    async buscaTurmasRelacionadas() {
      const novo = {
        ano: this.ano,
        escola_id:this.escola_id,
        segmento_id: this.etapa_id,
      }

      const data = await Turma.showEscolaSegmentoTurma(novo);
      this.turmasRelacionadas = [];
      for (const el of data.data) {
        //nao mostra a turma que ele atualmente está
        if (el.id == this.turma_id)
          {continue;}
        this.turmasRelacionadas.push(el);
      }
    },
  },
  async beforeMount(){
    try {
      if(this.turma_id != null){
        this.situacaoAno = parseInt(sessionStorage.getItem("anoSitucao"));
        this.ano = parseInt(sessionStorage.getItem("anoSelecionado"));        //this.ano = new Date().getFullYear();
        await this.turmaDisciplina();
        await this.turmaConfig();
        await this.identificarTipoSegmento();
        await this.identificarTipoSerie();
        await this.buscarAlunoNaTurma(0);
        this.matriculaEncerrada === true ? this.selectType = "single" : this.selectType = "multiple";

        if (this.tipo_segmento === 3) {
          this.periodos = [
            {id: 1, nome: '1° período'},
            {id: 2, nome: '2° período'},
          ]
        }
      }else{
        this.$router.push({ name: "turmas"});
      }
    } catch (e) {
      this.carregandoPagina = false;
      console.log(e);
    }
  }
});
</script>

<style>
  #alunturma .p-picklist-source-controls{
   display:none;
 }
 #alunturma .p-picklist-target-controls{
   display:none;
 }

 #alunturma .p-picklist-transfer-buttons .p-button:nth-child(2) {
    display:none;
 }

 #alunturma .p-picklist-transfer-buttons .p-button:nth-child(4) {
    display:none;
 }

 .badge-secondary2 {
    color: #fff;
    background-color: #6c757d;
    font-size: 9px;
 }

 .cabecario-turma2 {
    background-color: rgb(59, 130, 246);
    color: #fff;
    margin-left: 15px;
    padding: 8px;
    border-radius: 5px;
    text-transform: uppercase;
    font-size: 13px;
    vertical-align:middle !important
}
</style>

<style lang="scss" scoped>
.virtualscroller-demo {
	::v-deep(.p-virtualscroller) {
		height: 200px;
		width: 200px;
		border: 1px solid var(--surface-border);

        .scroll-item {
            background-color: var(--surface-card);
            display: flex;
            align-items: center;
        }

        .custom-scroll-item {
            flex-direction: column;
            align-items: stretch;
        }

        .odd {
            background-color: var(--surface-ground);
        }
	}

    ::v-deep(.p-horizontal-scroll) {
        .p-virtualscroller-content {
            display: flex;
            flex-direction: row;
        }

        .scroll-item {
            writing-mode: vertical-lr;
        }
    }

    ::v-deep(.custom-loading > .p-virtualscroller-loader) {
        display: block;
    }
}
</style>
